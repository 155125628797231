<template>
  <div>
    <cabecalho-pagina
      v-if="!ehAgendamento"
      :titulo="$t('modulos.operacao.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.operacao.titulos.novo')"
      sem-botao-ok
      :sem-filtro="!buscarPermissao('Operacao', 'Listar')"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>

    <data-table
      :value="tabela.dados"
      show-gridlines
      edit-mode="row"
      :paginator="false"
      class="p-datatable-sm tabela-prime-vue-inherit"
      :expanded-rows.sync="expandedRows"
      :rows="50"
      striped-rows
      :reorderable-columns="true"
      data-key="id"
      :row-hover="true"
      :selection.sync="tabela.selecionados"
      responsive-layout="scroll"
      @update:sortOrder="atualizarSentidoOrdenacao"
      @update:sortField="atualizarOrdenacaoCampo"
    >
      <template #header>
        <div
          v-if="!ehAgendamento"
          class="d-flex justify-end"
        >
          <span class="p-input-icon-left mr-2">
            <i class="pi pi-search" />
            <InputText
              v-model="valueFiltroGlobal"
              placeholder="Filtrar"
              class="p-inputtext-sm"
              @keydown.enter="filtrarGlobal"
              @input="filtroGlobal"
            />
          </span>
          <Dropdown
            v-model="setorPadrao"
            :options="opcoesSetores"
            option-label="name"
            placeholder="Setores"
            :filter="true"
            :show-clear="true"
            class="mr-2 p-inputtext-sm"
            @input="filtrarSelecionadoSelectSetor"
          />
          <botao-padrao
            outlined
            tooltip="Colunas"
            @click="abreModalDefineColunas"
          >
            <v-icon>$formatColumns</v-icon>
          </botao-padrao>
        </div>
      </template>
      <Column
        :expander="true"
        :styles="{ width: '3%' }"
      />
      <Column
        field="acoes"
        header=""
        :styles="{ width: '3%' }"
      >
        <template #body="slotProps">
          <div class="d-flex">
            <dropdown-padrao
              :ref="`menuAcoes${slotProps.index}`"
              :key="slotProps.index"
              color="secondary"
              text
            >
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>

              <v-list-item-group style="z-index: 1 !important">
                <v-list-item
                  v-if="buscarPermissao('OrdemServico', 'Editar')"
                  class="d-flex"
                  @click="abrirEditarOrdemServico(slotProps.data)"
                >
                  {{ $t('modulos.ordem_servico.titulos.editar') }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  v-if="buscarPermissao('OrdemServico', 'Imprimir')"
                  class="d-flex"
                  @click="imprimir(slotProps.data)"
                >
                  {{ $t('geral.botoes.imprimir') }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  v-if="buscarPermissao('Operacao', 'AnaliseTecnica')"
                  class="d-flex"
                  :disabled="
                    slotProps.data.situacao != 'AguardandoAnaliseTecnica'
                  "
                  @click="direcionaParaAnaliseTecnicaEmLote(slotProps.data)"
                >
                  {{ $t('modulos.operacao.botoes.analise_tecnica_em_lote') }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  v-if="buscarPermissao('Operacao', 'GerarOrcamento')"
                  class="d-flex"
                  :disabled="
                    slotProps.data.situacao !== 'AguardandoEnvioOrcamento' &&
                      slotProps.data.situacao !== 'AguardandoAprovacaoOrcamento'
                  "
                  @click="gerarOrcamento(slotProps.data)"
                >
                  {{ $t('modulos.operacao.botoes.gerar_orcamento') }}
                </v-list-item>
                <v-divider />

                <dropdown-padrao-item
                  v-if="buscarPermissao('Operacao', 'Manutencao')"
                  :disabled="!podeFazerManutencaoOS(slotProps.data)"
                  :tooltip="
                    podeFazerManutencaoOS(slotProps.data)
                      ? ''
                      : 'Selecione um setor para fazer manutenção pela OS'
                  "
                  @click="
                    direcionaParaManutencaoPecasExecutadas(slotProps.data)
                  "
                >
                  {{ $t('modulos.operacao.botoes.manutencao') }}
                </dropdown-padrao-item>

                <v-list-item
                  v-if="buscarPermissao('Operacao', 'PlanoInspecao')"
                  class="d-flex"
                  :disabled="
                    slotProps.data.situacaoAuxiliar !==
                      'AguardandoPlanoInspecao'
                  "
                  @click="direcionaPlanoInspecao(slotProps.data)"
                >
                  {{ $t('modulos.operacao.botoes.plano_inspecao') }}
                </v-list-item>
                <v-divider />
                <dropdown-padrao-item
                  v-if="buscarPermissao('Operacao', 'Entrega')"
                  :disabled="
                    slotProps.data.flagExisteEnvioMaterial ||
                      slotProps.data.situacaoAuxiliar !== 'AguardandoEntrega'
                  "
                  :tooltip="
                    slotProps.data.flagExisteEnvioMaterial
                      ? $t('modulos.operacao.tooltips.entrega_ordem_servico')
                      : ''
                  "
                  @click="entrega(slotProps.data)"
                >
                  {{ $t('geral.botoes.entrega') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('OrdemServico', 'OrdemServicoParcial')"
                  :disabled="!podeAbrirOSParcial(slotProps.data)"
                  @click="abrirOsParcial(slotProps.data)"
                >
                  {{ $t('modulos.ordem_servico.botoes.os_parcial') }}
                </dropdown-padrao-item>

                <dropdown-padrao-item
                  v-if="buscarPermissao('Operacao', 'MovimentarSituacao') && podeMovimentarSituacao(slotProps.data)"
                  @click="abrirMovimentarSituacao(slotProps.data)"
                >
                  {{ $t('modulos.ordem_servico.botoes.movimentar_situacao') }}
                </dropdown-padrao-item>

                <dropdown-padrao
                  submenu
                  color="secondary"
                >
                  <template #botao>
                    <v-list-item-group style="font-weight: 400">
                      {{ $t('geral.botoes.movimentar') }}
                    </v-list-item-group>
                  </template>
                  <v-list-item-group style="overflow-y: auto">
                    <v-list-item
                      v-if="
                        buscarPermissao('Operacao', 'MovimentarProximoSetor')
                      "
                      class="d-flex"
                      @click="
                        abrirModalMovimentacaoSetor(
                          slotProps.data,
                          null,
                          'Proximo',
                          true,
                          `menuAcoes${slotProps.index}`
                        )
                      "
                    >
                      {{
                        $t('modulos.operacao.botoes.movimentar_proximo_setor')
                      }}
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      class="d-flex"
                      @click="
                        abrirModalMovimentacaoSetor(
                          slotProps.data,
                          null,
                          'Anterior',
                          true,
                          `menuAcoes${slotProps.index}`
                        )
                      "
                    >
                      {{
                        $t('modulos.operacao.botoes.movimentar_setor_anterior')
                      }}
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      v-if="buscarPermissao('Operacao', 'MovimentarOutroSetor')"
                      class="d-flex"
                      @click="
                        abrirModalMovimentacaoSetor(
                          slotProps.data,
                          null,
                          'Outro',
                          true,
                          `menuAcoes${slotProps.index}`
                        )
                      "
                    >
                      {{ $t('modulos.operacao.botoes.movimentar_outro_setor') }}
                    </v-list-item>
                  </v-list-item-group>
                </dropdown-padrao>
              </v-list-item-group>
            </dropdown-padrao>
            <div class="d-flex justify-start">
              <icone-padrao
                v-if="
                  slotProps.data.situacao == 'AguardandoEnvioOrcamento' ||
                    slotProps.data.situacao == 'AguardandoAprovacaoOrcamento'
                "
                class="mr-2"
                :icone="getSituacaoIcon(slotProps.data.situacao)"
                :tooltip="getSituacaoMessage(slotProps.data.situacao)"
                @click="gerarOrcamento(slotProps.data)"
              />
              <icone-padrao
                v-else
                class="mr-2"
                :icone="getSituacaoIcon(slotProps.data.situacao)"
                :tooltip="getSituacaoMessage(slotProps.data.situacao)"
              />
              <span
                v-if="
                  slotProps.data.situacaoAuxiliar == 'EmAndamento' ||
                    slotProps.data.situacaoAuxiliar == 'Concluido'
                "
                style="width: 24px"
              />
              <icone-padrao
                v-if="
                  slotProps.data.situacaoAuxiliar != 'EmAndamento' &&
                    slotProps.data.situacaoAuxiliar != 'Concluido'
                "
                :icone="getSituacaoIcon(slotProps.data.situacaoAuxiliar)"
                :tooltip="getSituacaoMessage(slotProps.data.situacaoAuxiliar)"
              />
            </div>
          </div>
        </template>
      </Column>
      <column
        v-for="(col, index) of colunasSelecionadas"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :sortable="col.sortable"
        :tipo="col.tipo ?? 'string'"
        :styles="col.styles"
      >
        <template #body="slotProps">
          <slot
            v-if="col.field == 'quantidade'"
            :slotProps="slotProps"
            :name="col.field"
          >
            {{
              formatarQtdInstrumentos(
                slotProps.data.quantidadeInstrumentosSetor,
                slotProps.data.quantidadeInstrumentos
              )
            }}
          </slot>
          <slot
            v-if="col.field == 'status'"
            :slotProps="slotProps"
            :name="col.field"
          >
            <icone-padrao
              :icone="getSituacaoIcon(slotProps.data.situacao)"
              :tooltip="getSituacaoMessage(slotProps.data.situacao)"
            />
          </slot>
          <slot
            v-else
            :slotProps="slotProps"
            :name="col.field"
          >
            {{ formatarValorColuna(col, slotProps.data) }}
          </slot>
        </template>
      </column>

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            striped-rows
            class="tabela-prime-vue-inherit tabela-instrumento"
            :value="slotProps.data.instrumentos"
            edit-mode="row"
          >
            <Column
              field="acoes"
              header=""
              :styles="{ width: '1%' }"
            >
              <template #body="{ data }">
                <div class="d-flex">
                  <dropdown-padrao
                    :ref="`menuAcoesExpansao${slotProps.index}`"
                    text
                    color="secondary"
                  >
                    <template #botao>
                      <v-icon> $dotsVertical </v-icon>
                    </template>
                    <v-list-item-group style="overflow-y: auto">
                      <v-list-item
                        v-if="buscarPermissao('Operacao', 'Editar')"
                        @click="editarInstrumento(data)"
                      >
                        {{ $t('modulos.operacao.botoes.editar_instrumento') }}
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        v-if="buscarPermissao('Operacao','PontosCalibracao')"
                        @click="abrirPontosCalibracao(data)"
                      >
                        {{ $t('modulos.operacao.botoes.pontos_calibracao') }}
                      </v-list-item>
                      <dropdown-padrao-item
                        v-if="buscarPermissao('Operacao', 'Calibracao')"
                        :disabled="!(podeCalibrar(data) && data.flagCalibracao)"
                        :tooltip="
                          podeCalibrar(data) && data.flagCalibracao
                            ? ''
                            : $t('modulos.operacao.tooltips.calibracao')
                        "
                        @click="
                          confirmacaoAbrirCalibracao(slotProps.data.id, data)
                        "
                      >
                        {{ $t('modulos.operacao.botoes.calibracao') }}
                      </dropdown-padrao-item>
                      <v-divider />
                      <v-list-item
                        v-if="buscarPermissao('Operacao','VisualizarHistorico')"
                        @click="abrirInstrumentoHistorico(data)"
                      >
                        {{ $t('modulos.operacao.botoes.historico_instrumento') }}
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        v-if="buscarPermissao('Operacao', 'AnaliseTecnica')"
                        :disabled="data.situacao !== 'AguardandoAnaliseTecnica'"
                        @click="
                          direcionaParaAnaliseTecnica(slotProps.data, data)
                        "
                      >
                        {{ $t('modulos.operacao.botoes.analise_tecnica') }}
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        v-if="buscarPermissao('Operacao', 'AprovarGarantia')"
                        :disabled="
                          slotProps.data.situacao !==
                            'AguardandoAprovacaoGarantia'
                        "
                        @click="direcionaParaAprovacaoGarantia(data)"
                      >
                        {{ $t('modulos.operacao.botoes.aprovar_garantia') }}
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        :disabled="!(
                          data.situacao ==
                          'AguardandoProgramacaoManutencao' ||
                          data.situacao == 'ProgramacaoTerceiroDia' ||
                          data.situacao == 'ProgramacaoSegundoDia' ||
                          data.situacao == 'ProgramacaoDia' ||
                          data.situacao == 'EmExecucaoManutencao'
                        )
                        "
                        @click="
                          direcionaParaManutencaoPecasExecutadas(
                            slotProps.data,
                            data
                          )
                        "
                      >
                        {{ $t('modulos.operacao.botoes.manutencao') }}
                      </v-list-item>
                      <v-divider />
                      <dropdown-padrao-item
                        v-if="buscarPermissao('Operacao', 'Calibracao')"
                        :disabled="!(podeCalibrar(data) && data.flagCalibracao)"
                        :tooltip="
                          podeCalibrar(data) && data.flagCalibracao
                            ? ''
                            : $t('modulos.operacao.tooltips.calibracao')
                        "
                        @click="
                          confirmacaoAbrirCalibracao(slotProps.data.id, data)
                        "
                      >
                        {{ $t('modulos.operacao.botoes.calibracao') }}
                      </dropdown-padrao-item>
                      <dropdown-padrao-item
                        :disabled="
                          !(
                            participanteLogadoConta &&
                            podeCalibrarTerceiro(data)
                          )
                        "
                        @click="
                          confirmacaoAbrirCalibracaoTerceiro(
                            slotProps.data,
                            data
                          )
                        "
                      >
                        {{ $t('modulos.operacao.botoes.calibracao_terceiros') }}
                      </dropdown-padrao-item>
                      <dropdown-padrao
                        submenu
                        color="secondary"
                      >
                        <template #botao>
                          <v-list-item-group style="font-weight: 400">
                            {{ $t('geral.botoes.certificado') }}
                          </v-list-item-group>
                        </template>
                        <v-list-item-group style="overflow-y: auto">
                          <v-list-item
                            :disabled="!podeVisualizarCertificado(data)"
                            @click="
                              abrirCertificado(
                                slotProps.data,
                                data,
                                `menuAcoesExpansao${slotProps.index}`
                              )
                            "
                          >
                            {{
                              $t(
                                'modulos.operacao.botoes.visualizar_certificado'
                              )
                            }}
                          </v-list-item>
                          <v-divider />
                          <dropdown-padrao-item
                            :tooltip="
                              tooltipAssinar(data.flagCertificadoAssinar)
                            "
                            :disabled="!data.flagCertificadoAssinar"
                            :divider="false"
                            @click="assinarCertificado(data)"
                          >
                            {{
                              $t(
                                'modulos.certificados.botoes.assinar_certificado'
                              )
                            }}
                          </dropdown-padrao-item>
                          <v-divider />
                          <v-list-item
                            :disabled="!podeCancelar(data)"
                            @click="
                              cancelarCertificado(
                                data,
                                `menuAcoesExpansao${slotProps.index}`
                              )
                            "
                          >
                            {{
                              $t(
                                'modulos.certificados.botoes.cancelar_certificado'
                              )
                            }}
                          </v-list-item>
                          <v-divider />
                          <v-list-item
                            :disabled="!podeCancelarSubstituir(data)"
                            @click="
                              cancelarSubstituirCalibracao(
                                data,
                                `menuAcoesExpansao${slotProps.index}`
                              )
                            "
                          >
                            {{
                              $t(
                                'modulos.certificados.botoes.cancelar_substituir'
                              )
                            }}
                          </v-list-item>
                        </v-list-item-group>
                      </dropdown-padrao>
                      <v-divider />
                      <dropdown-padrao
                        submenu
                        color="secondary"
                      >
                        <template #botao>
                          <v-list-item-group style="font-weight: 400">
                            {{ $t('geral.botoes.movimentar') }}
                          </v-list-item-group>
                        </template>
                        <v-list-item-group style="overflow-y: auto">
                          <v-list-item
                            @click="
                              abrirModalMovimentacaoSetor(
                                slotProps.data,
                                data,
                                'Proximo',
                                false,
                                `menuAcoesExpansao${slotProps.index}`
                              )
                            "
                          >
                            {{
                              $t(
                                'modulos.operacao.botoes.movimentar_proximo_setor'
                              )
                            }}
                          </v-list-item>
                          <v-divider />
                          <v-list-item
                            @click="
                              abrirModalMovimentacaoSetor(
                                slotProps.data,
                                data,
                                'Anterior',
                                false,
                                `menuAcoesExpansao${slotProps.index}`
                              )
                            "
                          >
                            {{
                              $t(
                                'modulos.operacao.botoes.movimentar_setor_anterior'
                              )
                            }}
                          </v-list-item>
                          <v-divider />
                          <v-list-item
                            @click="
                              abrirModalMovimentacaoSetor(
                                slotProps.data,
                                data,
                                'Outro',
                                false,
                                `menuAcoesExpansao${slotProps.index}`
                              )
                            "
                          >
                            {{
                              $t(
                                'modulos.operacao.botoes.movimentar_outro_setor'
                              )
                            }}
                          </v-list-item>
                        </v-list-item-group>
                      </dropdown-padrao>
                    </v-list-item-group>
                  </dropdown-padrao>
                  <div class="d-flex">
                    <icone-padrao
                      v-if="data.situacao == 'AguardandoAnaliseTecnica'"
                      class="mr-2"
                      :icone="getSituacaoIcon(data.situacao)"
                      :tooltip="getSituacaoMessage(data.situacao)"
                      @click="direcionaParaAnaliseTecnica(slotProps.data, data)"
                    />
                    <icone-padrao
                      v-else-if="
                        (data.situacao == 'EmExecucaoCalibracao' ||
                          data.situacao == 'AguardandoProgramacaoCalibracao') &&
                          podeCalibrar(data) &&
                          data.flagCalibracao
                      "
                      class="mr-2"
                      :icone="getSituacaoIcon(data.situacao)"
                      :tooltip="getSituacaoMessage(data.situacao)"
                      @click="
                        confirmacaoAbrirCalibracao(slotProps.data.id, data)
                      "
                    />

                    <icone-padrao
                      v-else-if="
                        slotProps.data.situacao == 'AguardandoEnvioOrcamento' ||
                          slotProps.data.situacao ==
                          'AguardandoAprovacaoOrcamento'
                      "
                      class="mr-2"
                      :icone="getSituacaoIcon(data.situacao)"
                      :tooltip="getSituacaoMessage(data.situacao)"
                      @click="gerarOrcamento(slotProps.data)"
                    />
                    <icone-padrao
                      v-else
                      class="mr-2"
                      :icone="getSituacaoIcon(data.situacao)"
                      :tooltip="getSituacaoMessage(data.situacao)"
                    />

                    <icone-padrao
                      v-if="data.flagAguardandoSuplemento"
                      class="mr-2"
                      :icone="getSituacaoIcon('AguardandoSuplemento')"
                      :tooltip="getSituacaoMessage('AguardandoSuplemento')"
                    />
                  </div>
                </div>
              </template>
            </Column>
            <Column
              field="codigo"
              header="Instrumento"
              :styles="{ width: '15%' }"
            />
            <Column
              field="tipoInstrumento.nome"
              header="Tipo de Instrumento"
              :styles="{ width: '15%' }"
            />
            <Column
              field="setor.nome"
              header="Setor"
              :styles="{ width: '15%' }"
            />
            <!-- <Column
              field="situacao"
              header="Situação"
              :styles="{ width: '10%', 'text-align': 'center' }"
            >
              <template #body="{ data }">
                <icone-padrao
                  :icone="getSituacaoIcon(data.situacao)"
                  :tooltip="getSituacaoMessage(data.situacao)"
                />
              </template>
            </Column> -->
            <Column
              field="faixas"
              header="Faixas"
              :styles="{ width: '10%', 'text-align': 'center' }"
            >
              <template #body="{ data }">
                {{ data.faixas.length }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </data-table>
    <Paginator
      v-if="!ehAgendamento"
      :rows.sync="itensPorpagina"
      :first.sync="paginaAtual"
      :total-records="tabela.quantidadeItens"
      :rows-per-page-options="[10, 25, 50, 100, 500]"
      current-page-report-template="({currentPage} de {totalPages})"
      template="JumpToPageInput FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    />
    <DefineColunasModalPrimeVue
      ref="modal-define-colunas"
      :colunas="tabela.colunas"
      :selecionadas="novasColunas"
      @envia-colunas-selecionadas="refazColunasTabela"
    />
    <MovimentarProximoAnteriorSetor
      ref="modal-movimentar-proximo-anterior-setor"
      :instrumento-nome="instrumentoNome"
      :tipo-instrumento-nome="tipoInstrumentoNome"
      :ordem-servico-nome="ordemServicoNome"
      @atualizaListagem="listarRegistros"
    />
    <modal-visualizacao-relatorio
      ref="modal-pdf"
      titulo="Visualizar Relatório"
    />
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
import CalibracaoService from '@common/services/cadastros/CalibracaoService';
import CertificadoService from '@common/services/cadastros/CertificadoService';
import CertificadoTerceiroService from '@common/services/cadastros/CertificadoTerceiroService';
import OperacaoService from '@common/services/cadastros/OperacaoService';
// import OrcamentosService from '@common/services/cadastros/OrcamentosService';
import OrdemServicoService from '@common/services/cadastros/OrdemServicoService';
import SetorService from '@common/services/cadastros/SetorService';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import { ModalVisualizacaoRelatorio } from '@components/misc';
import DefineColunasModalPrimeVue from '@components/tabelas/DefineColunasModalPrimeVue.vue';
import { enumOperacaoSituacaoIcons } from '@enums/icons';
// import _ from 'lodash';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import MovimentarProximoAnteriorSetor from './components/modais/MovimentarProximoAnteriorSetor.vue';
import Filtro from './filtro';

export default {
  components: {
    ModalVisualizacaoRelatorio,
    CabecalhoPagina,
    Filtro,
    DefineColunasModalPrimeVue,
    MovimentarProximoAnteriorSetor,
  },
  props: ['ehAgendamento', 'codigoOSAgendamento'],
  data() {
    return {
      valueFiltroGlobal: null,
      opcoesSetores: [],
      expandedRows: [],
      tabela: {
        selecionados: [],
        dados: [],
        quantidadeItens: 0,
        quantidadePaginas: 1,
        paginaAtual: 1,
        porPagina: 10,
        colunas: [
          {
            text: this.$t('modulos.operacao.tabela.ordem_servico'),
            sortable: true,
            value: 'codigo',
          },
          {
            text: this.$t('modulos.operacao.tabela.cliente'),
            sortable: true,
            value: 'participante.nome',
          },
          // {
          //   text: this.$t('modulos.operacao.tabela.status'),
          //   sortable: true,
          //   value: 'status',
          //   styles: { width: '8%', 'text-align': 'center' },
          // },
          {
            text: this.$t('modulos.operacao.tabela.quantidade'),
            sortable: false,
            value: 'quantidade',
            styles: { width: '15%', 'text-align': 'center' },
          },
          {
            text: this.$t('modulos.operacao.tabela.setores'),
            sortable: false,
            value: 'setoresTexto',
            styles: { 'text-align': 'center' },
          },
          //contratos - aguardando validação do cliente
          // {
          //   text: this.$t('modulos.operacao.tabela.contrato'),
          //   sortable: true,
          //   value: 'contratos',
          // },
          {
            text: this.$t('modulos.operacao.tabela.recebimento'),
            sortable: true,
            value: 'dataRecebimento',
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            text: this.$t('modulos.operacao.tabela.previsao_entrega'),
            sortable: true,
            value: 'dataPrevisaoEntrega',
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
        ],
      },
      tabelaInterna: {
        colunas: [
          {
            sortable: false,
            value: 'acoes',
            text: '',
          },
          {
            text: this.$t('modulos.operacao.tabela.instrumento'),
            sortable: true,
            value: 'codigo',
          },
          {
            text: this.$t('modulos.operacao.tabela.tipo_instrumento'),
            sortable: true,
            value: 'tipoInstrumento.nome',
          },
          {
            text: this.$t('modulos.operacao.tabela.setor'),
            sortable: true,
            value: 'setor.nome',
          },
          {
            text: this.$t('modulos.operacao.tabela.faixas'),
            sortable: true,
            value: 'faixas',
          },
        ],
      },
      filtroAplicado: { filter: '' },
      novasColunas: [],
      colunasSelecionadas: [],
      colunasKey: 0,
      itensPorpagina: 10,
      passo: 0,
      paginaAtual: 1,
      numeroItens: 1,
      desabilitado: true,
      setorId: null,
      setorPadrao: null,
      instrumentoNome: null,
      tipoInstrumentoNome: null,
      ordemServicoNome: null,
      campo: '',
      ordem: 1,
      colunaOrdenada: ''
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta', 'usuario']),
  },
  watch: {
    paginaAtual() {
      this.enviaDadosPaginacao();
    },
    itensPorpagina() {
      this.enviaDadosPaginacao();
    },
  },
  created() {
    // helpers.redirecionarSemPermissao(this, 'Operacao', 'Listar');
    // const filtros = this.buscarStateFiltros();
    // this.filtroAplicado = filtros?.filtroAplicado ?? {};
    // this.porPagina = filtros?.porPaginaPaginator ?? 10;
    // this.paginaAtual = filtros?.paginaAtualPaginator ?? 1;
    // this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    // this.tabela.porPagina = filtros?.porPagina ?? 10;
    // this.setorId =
    //   filtros?.setorId ??
    //   (filtros?.setorRemovidoPeloUsuario
    //     ? (this.setorPadrao = null)
    //     : this.usuario?.setorId);
  },

  mounted() {
    this.colunaOrdenada = '-codigo'
    helpers.redirecionarSemPermissao(this, 'Operacao', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.itensPorpagina = filtros?.porPaginaPaginator ?? 10;
    this.paginaAtual = filtros?.paginaAtualPaginator ?? 1;
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
    this.valueFiltroGlobal = filtros?.filtroAplicado?.filter ?? '';

    this.setorId =
      filtros?.setorId ??
      (filtros?.setorRemovidoPeloUsuario
        ? (this.setorPadrao = null)
        : this.usuario?.setorId);
    this.buscaSetoresParticipante();
    if (!this.ehAgendamento)
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.operacao.titulos.listagem')
      );
    this.tabela.colunas?.forEach((coluna) => {
      coluna.field = coluna.value;
      coluna.header = coluna.text;
    });
    this.colunasSelecionadas = this.tabela.colunas;
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.tabela.paginaAtual = 1;
      this.paginaAtual = 0;
      this.listarRegistros();
    },
    filtrarSelecionadoSelectSetor() {
      this.setorId = this.setorPadrao?.code;
      this.listarRegistros();
    },
    filtrar: function (filtro) {
      this.tabela.paginaAtual = 1;
      this.paginaAtual = 0;
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.paginaAtual = paginaAtual;
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
        paginaAtualPaginator: this.paginaAtual,
        porPaginaPaginator: this.itensPorpagina,
        setorId: this.setorId,
        setorRemovidoPeloUsuario: this.setorId ? false : true,
      });

      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        setorMovimentoId: this.setorId,
        sort: this.colunaOrdenada,
      };

      const { setor, ordemServico } = this.$route.query;
      if (setor && ordemServico) {
        parametros.setor = setor;
        parametros.filter = ordemServico;
      }

      if (!this.setorId || this.setorId == 'null' || this.ehAgendamento)
        delete parametros.setorMovimentoId;

      if (this.ehAgendamento) {
        parametros.codigoOrdemServico = this.codigoOSAgendamento;
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      OperacaoService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    entrega(item) {
      this.$router.push({
        name: 'operacao-entrega',
        params: { id: item.id },
      });
    },

    auxFecharMenuQuandoClicarNoSubmenu(refMenuPai) {
      // Gambiarra para fechar menu pai quando clicar nos submenus
      this.$nextTick(() => {
        if (this.$refs[refMenuPai]) {
          this.$refs[refMenuPai].$refs['menu'].save();
        }
      });
    },

    abrirModalMovimentacaoSetor(
      ordemServico,
      instrumento,
      movimento,
      somenteOS = false,
      refMenuPai
    ) {
      let ordemServicoId = ordemServico.id;
      let instrumentoId = instrumento?.instrumentoId;

      this.tipoInstrumentoNome = instrumento?.tipoInstrumento?.nome
        ? instrumento.tipoInstrumento.nome
        : '';
      this.instrumentoNome = instrumento?.codigo ? instrumento.codigo : '';
      this.ordemServicoNome = ordemServico.codigo;
      this.$refs['modal-movimentar-proximo-anterior-setor'].abrirModal(
        ordemServicoId,
        instrumentoId,
        movimento,
        somenteOS
      );
      this.auxFecharMenuQuandoClicarNoSubmenu(refMenuPai);
    },
    gerarOrcamento(item) {
      const orcamentoExistente = [
        'AguardandoEnvioCliente',
        'AguardandoLiberacao',
      ];
      const orcamentoJaEnviado = [
        'AguardandoAprovacao',
        'Aprovado',
        'Reprovado',
      ];

      if (
        item?.orcamento?.id &&
        orcamentoExistente.includes(item.orcamento?.status)
      ) {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.operacao.avisos.orcamento_existente')
        ).then(() => {
          this.$router.push({
            name: 'orcamentos-editar',
            params: { id: item.orcamento?.id },
          });
        });
        return;
      } else if (
        item?.orcamento?.id &&
        orcamentoJaEnviado.includes(item.orcamento?.status)
      ) {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.operacao.avisos.orcamento_enviado')
        ).then(() => {
          this.$store.dispatch(
            'Layout/iniciarCarregamento',
            'Gerando Orçamento'
          );
          OrdemServicoService.gerarOrcamentoOrdemServico(item.id, true)
            .then((res) => {
              this.$router.push({
                name: 'orcamentos-editar',
                params: { id: res.data },
              });
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
            });
        });

        return;
      } else {
        this.confirmarGerarOrcamentoOS(item.codigo).then(() => {
          this.$store.dispatch(
            'Layout/iniciarCarregamento',
            'Gerando Orçamento'
          );
          OrdemServicoService.gerarOrcamentoOrdemServico(item.id)
            .then((res) => {
              this.$router.push({
                name: 'orcamentos-editar',
                params: { id: res.data },
              });
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
            });
        });
      }

      //
    },
    buscaSetoresParticipante() {
      let params = {
        page: 1,
        amountPerPage: 100,
        participanteId: localStorage.getItem('participanteLogadoId'),
      };
      SetorService.listar(params)
        .then((res) => {
          if (res.data.items.length) {
            res.data.items.forEach((item) => {
              this.opcoesSetores.push({
                name: item.nome,
                code: item.id,
              });
            });
          }
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.setor.erros.id_invalido'));
        })
        .finally(() => {
          this.listarRegistros();

          this.opcoesSetores.forEach((item) => {
            if (item.code == this.setorId) {
              this.setorPadrao = item;
            }
          });

          const { setor, ordemServico, setorId } = this.$route.query;

          if (setor && ordemServico && setorId)
            this.setorPadrao = this.opcoesSetores.find(
              (item) => item.code == setorId
            );
        });
    },
    enviaDadosPaginacao: async function () {
      await this.$nextTick();
      this.passo = this.paginaAtual / this.itensPorpagina + 1;
      this.listarRegistros(this.passo, this.itensPorpagina);
    },
    formatarValorColuna(coluna, data) {
      const valor = this.obterValorCampo(data, coluna.field);
      return Object.hasOwn(coluna, 'formatter')
        ? coluna.formatter(valor)
        : valor;
    },
    obterValorCampo: get,
    abreModalDefineColunas() {
      this.$refs['modal-define-colunas'].abrirModal();
    },
    refazColunasTabela(colunas) {
      if (colunas.length) {
        this.novasColunas = [];
        this.tabela.colunas.forEach((col) =>
          colunas.forEach((novaColuna) => {
            if (col.header == novaColuna.coluna) {
              this.novasColunas.push(col);
            }
          })
        );
        this.aoSelecionarColuna(this.novasColunas);
      }
    },
    aoSelecionarColuna(value) {
      this.colunasSelecionadas = this.tabela.colunas.filter((col) =>
        value.includes(col)
      );
      ++this.colunasKey;
    },
    imprimir: function (item) {
      this.$refs['modal-pdf'].abrirModal('ordem-servico', [
        { simbolo: 'id', valor: item.id },
      ]);
    },
    abrirEditarOrdemServico(item) {
      this.$router.push({
        name: 'ordem-servico-editar',
        params: { id: item.id },
      });
    },
    getSituacaoIcon(situacao) {
      if (situacao) {
        const icon = enumOperacaoSituacaoIcons.find(
          (el) => el.value === situacao
        ).icon;
        return this.$vuetify.icons.values[icon];
      }
    },
    getSituacaoMessage(situacao) {
      if (situacao) {
        return enumOperacaoSituacaoIcons.find((el) => el.value === situacao)
          .message;
      }
    },
    editarInstrumento(value) {
      const { instrumentoId } = value;
      this.$router.push({
        name: 'instrumento-editar',
        params: { id: instrumentoId, retorno: 'operacao' },
      });
    },
    confirmacaoAbrirCalibracao(ordemId, instrumento) {
      if (!instrumento.flagTemServicoCalibracaoAprovado) {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.operacao.confirmacao_calibracao_nao_aprovada')
        )
          .then(() => {
            this.$store.dispatch('Layout/iniciarCarregamento');
            OperacaoService.instrumentoSemCalibração(ordemId, {
              instrumentoId: instrumento.instrumentoId,
            })
              .then(() => {
                this.toastSucesso(
                  this.$t(
                    `modulos.operacao.confirmacao_calibracao_nao_aprovada_sucesso`
                  )
                );
                this.listarRegistros();
              })
              .finally(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              });
          })
          .catch(() => {});

        return;
      }

      if (instrumento.calibracao?.situacao == 'EmAndamento') {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.operacao.confirmacao_calibracao')
        ).then(() => {
          this.abrirCalibracao(
            ordemId,
            instrumento.id,
            instrumento.instrumentoId
          );
        });
      } else {
        this.abrirCalibracao(
          ordemId,
          instrumento.id,
          instrumento.instrumentoId
        );
      }
    },
    abrirCalibracao: function (
      ordemId,
      instrumentoId,
      instrumentoAnaliseTecnicaId
    ) {
      this.$router.push({
        name: 'calibracao-editar',
        params: {
          ordemServicoId: ordemId,
          instrumentoId: instrumentoId,
          instrumentoAnaliseTecnicaId,
        },
      });
    },
    podeCalibrar: function (instrumento) {
      const arraySituacao = [
        'AguardandoProgramacaoCalibracao',
        'ProgramacaoCalibracaoTerceiroDia',
        'ProgramacaoCalibracaoSegundoDia',
        'ProgramacaoCalibracaoDia',
        'EmExecucaoCalibracao',
      ];
      if (
        arraySituacao.includes(instrumento.situacao) &&
        instrumento.calibracao?.nivel != 'Terceiro'
      ) {
        return true;
      }

      return false;
    },
    direcionaParaAnaliseTecnica(item, instrumento) {
      let idInstrumento = instrumento.instrumentoId;
      let id = instrumento.analiseTecnicaId;
      let ordemServico = item.id;
      this.$router.push({
        name: 'operacao-analise-tecnica',
        params: { idInstrumento, ordemServico, id },
      });
    },
    direcionaParaAnaliseTecnicaEmLote(item) {
      let ordemServico = item.id;
      this.$router.push({
        name: 'operacao-analise-tecnica-em-lote',
        params: { ordemServico },
      });
    },
    direcionaPlanoInspecao(item) {
      let id = item.id;
      this.$router.push({
        name: 'operacao-plano-inspecao',
        params: { id },
      });
    },
    direcionaParaManutencaoPecasExecutadas(item, instrumento) {
      let idInstrumento = instrumento?.id;
      let setorId = instrumento?.setor?.id || this.setorPadrao.code;
      let codigo = item.codigo;
      let id = item.id;
      this.$router.push({
        name: 'operacao-manutencao-pecas-executadas',
        params: { id },
        query: { idInstrumento, setorId, codigo },
      });
    },
    direcionaParaAprovacaoGarantia(instrumento) {
      let id = instrumento.analiseTecnicaId;
      this.$router.push({
        name: 'operacao-analise-tecnica-aprovacao-garantia',
        params: { id },
      });
    },
    abrirCertificado: function (ordemServico, instrumento, refMenu) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.auxFecharMenuQuandoClicarNoSubmenu(refMenu);
      CalibracaoService.buscar(instrumento.instrumentoId, ordemServico.id)
        .then((res) => {
          this.$router.push({
            name: 'calibracao-certificado',
            params: { id: res.data.id },
            query: { retorno: 'operacao' },
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    formatarQtdInstrumentos(qtdInstrumentoSetor, qtdInstrumentos) {
      return `${qtdInstrumentoSetor}/${qtdInstrumentos}`;
    },
    assinarCertificado: function (instrumento) {
      this.$router.push({
        name: 'calibracao-certificado',
        params: { id: instrumento.calibracaoId },
        query: { assinar: true },
      });
    },
    tooltipAssinar: function (boolAssinar) {
      if (!boolAssinar) return this.$t('modulos.certificados.tooltip');
      return '';
    },
    tooltipCalibracao: function (boolCalibrar) {
      if (!boolCalibrar) return this.$t('modulos.certificados.tooltip');
      return '';
    },
    cancelarCertificado: function (instrumento, refMenu) {
      this.auxFecharMenuQuandoClicarNoSubmenu(refMenu);
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.certificados.confirmar_cancelar')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.cancelarCalibracao(instrumento.calibracaoId)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.certificados.cancelado_sucesso`)
            );
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    podeCancelar: function (instrumento) {
      // return (
      //   instrumento.calibracao?.situacao != 'Cancelado' &&
      //   !instrumento.calibracao?.flagCertificadoLiberadoParaCliente &&
      //   !instrumento.calibracao?.certificadoSubstituido?.id &&
      //   instrumento.calibracao?.id
      // );
      return instrumento.flagCertificadoCancelar;
    },
    podeCancelarSubstituir: function (instrumento) {
      return (
        instrumento.calibracao?.situacao != 'Cancelado' &&
        instrumento.calibracao?.flagCertificadoLiberadoParaCliente
      );
    },
    cancelarSubstituirCalibracao: function (item, refMenu) {
      this.auxFecharMenuQuandoClicarNoSubmenu(refMenu);
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.certificados.cancelar_substituir')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.cancelarCalibracao(item.calibracaoId, true)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.certificados.cancelado_sucesso`)
            );
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    confirmacaoAbrirCalibracaoTerceiro: function (ordemServico, item) {
      //VOLTAR COM ESSA VALIDAÇÃO QUANDO A KEL TERMINAR DE FAZER OS REGISTROS
      // if (!item.flagTemServicoCalibracaoAprovado) {
      //   this.confirmar(
      //     this.$t('geral.titulos.atencao'),
      //     this.$t('modulos.operacao.confirmacao_calibracao_nao_aprovada')
      //   )
      //     .then(() => {
      //       this.$store.dispatch('Layout/iniciarCarregamento');
      //       OperacaoService.instrumentoSemCalibração(ordemServico.id, {
      //         instrumentoId: item.instrumentoId,
      //       })
      //         .then(() => {
      //           this.toastSucesso(
      //             this.$t(
      //               `modulos.operacao.confirmacao_calibracao_nao_aprovada_sucesso`
      //             )
      //           );
      //           this.listarRegistros();
      //         })
      //         .finally(() => {
      //           this.$store.dispatch('Layout/terminarCarregamento');
      //         });
      //     })
      //     .catch(() => {});

      //   return;
      // }
      if (item.calibracao?.situacao == 'Assinado') {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.certificado_terceiro.popup.mensagem')
        ).then(() => {
          this.$store.dispatch('Layout/iniciarCarregamento');
          CertificadoTerceiroService.voltarAndamento(item.calibracao?.id).then(
            () => {
              this.$router
                .push({
                  name: 'certificado-terceiro-editar',
                  params: { id: item.calibracao?.id },
                  query: { retorno: 'operacao' },
                })
                .finally(() => {
                  this.$store.dispatch('Layout/terminarCarregamento');
                });
            }
          );
          return;
        });
      } else if (
        //item.calibracao?.id &&
        item.calibracao?.nivel == 'Terceiro' &&
        item.calibracao?.situacao != 'Cancelado'
      ) {
        this.$router.push({
          name: 'certificado-terceiro-editar',
          params: { id: item.calibracao?.id },
          query: { retorno: 'operacao' },
        });
        return;
      } else {
        this.$router.push({
          name: 'certificado-terceiro-novo',
          query: {
            retorno: 'operacao',
            ordemServicoId: ordemServico.id,
            ordemServicoCodigo: ordemServico.codigo,
            instrumentoId: item.instrumentoId,
            instrumentoCodigo: item.codigo,
            clienteParticipanteId: ordemServico.participante.id,
            clienteParticipanteNome: ordemServico.participante.nome,
            tipoInstrumentoNome: item.tipoInstrumento.nome,
          },
        });
      }
    },
    podeCalibrarTerceiro: function (item) {
      return item.calibracao?.nivel != 'Calibracao';
      // ESSA VERIFICAÇÃO VAI SER POSTA QUANDO A KEL TERMINAR DE FAZER OS CERT. TERCEIROS !!! NAO APAGAR!!
      // && item.flagTemServicoCalibracaoAprovado;
    },
    abrirOsParcial(item) {
      this.$router.push({
        name: 'ordem-servico-parcial',
        params: { id: item.id },
        query: { retorno: 'operacao' },
      });
    },
    podeMovimentarSituacao(ordemServico){
      const situacoesNaoPermitidas = ['NaoCalibrado', 'Calibrado', 'Reprovado'];
      return !situacoesNaoPermitidas.includes(ordemServico.situacao);
    },
    abrirMovimentarSituacao(item) {
      this.$router.push({
        name: 'ordem-servico-movimentar-situacao',
        query: { id: item.id, retorno: 'operacao' },
      });
    },
    podeVisualizarCertificado(item) {
      return !!item.calibracao?.id && item.calibracao?.nivel != 'Terceiro';
    },
    podeAbrirOSParcial(item) {
      return !item.dataEntrega && item.quantidadeInstrumentos > 1;
    },

    podeFazerManutencaoOS(item) {
      const podeFazerManutencao = [
        'AguardandoProgramacaoManutencao',
        'ProgramacaoTerceiroDia',
        'ProgramacaoSegundoDia',
        'ProgramacaoDia',
        'EmExecucaoManutencao',
      ];
      return (
        !item.flagConcluido &&
        podeFazerManutencao.includes(item.situacao) &&
        !!this.setorId
      );
    },
    atualizarSentidoOrdenacao(event) {
      this.ordem = event == 1 ? '' : '-';
      this.novaOrdenacao()
    },
    atualizarOrdenacaoCampo(event) {
      this.campo = event;
    },
    novaOrdenacao() {
      let campoOrdenado = "";
      if (this.campo.includes('.')) campoOrdenado = helpers.formatarColunasComPonto(this.campo);
      else campoOrdenado = helpers.primeiraLetraMaiuscula(this.campo);
      this.colunaOrdenada = this.ordem + campoOrdenado;
      this.listarRegistros()
    },
    abrirInstrumentoHistorico: function (value) {
      const { instrumentoId } = value;
      this.$router.push({
        name: 'instrumento-historico',
        params: { id: instrumentoId},
        query: { retorno: 'operacao' },
      });
    },
    abrirPontosCalibracao(value){
      const { instrumentoId } = value;
       this.$router.push({
        name: 'instrumento-pontos',
        params: { id: instrumentoId },
        query: { retorno: 'operacao' },
      });
    }
  },
};
</script>
<style lang="scss">
.alinhar-direita {
  display: flex;
  justify-content: right;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.001rem 0.5rem !important;
}

.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 4.7rem !important;
  height: 37px;
  padding-top: 12px;
}

// .p-datatable-wrapper {
//   overflow: hidden !important;
// }

.orders-subtable {
  padding: 0 0 16px 145px !important;
  .p-datatable-wrapper {
    table {
      thead {
        th {
          background-color: lighten(#35507b, 50%) !important;
          border-left: 4px solid lighten(#35507b, 50%) !important;
          border-right: 4px solid lighten(#35507b, 50%) !important;
        }
      }
      tbody {
        tr {
          td:first-child {
            border-left: 4px solid lighten(#35507b, 50%) !important;
          }
          td:last-child {
            border-right: 4px solid lighten(#35507b, 50%) !important;
          }
        }
        tr:last-child {
          td {
            border-bottom: 4px solid lighten(#35507b, 50%) !important;
          }
        }
      }
    }
  }
}
</style>
